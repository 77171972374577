var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Modes_container, _Modes_engine;
import { Slow } from "./Slow";
export class Modes {
    constructor(engine, container) {
        _Modes_container.set(this, void 0);
        _Modes_engine.set(this, void 0);
        __classPrivateFieldSet(this, _Modes_engine, engine, "f");
        __classPrivateFieldSet(this, _Modes_container, container, "f");
        this.slow = new Slow();
    }
    load(data) {
        if (!data) {
            return;
        }
        this.slow.load(data.slow);
        if (__classPrivateFieldGet(this, _Modes_container, "f")) {
            const interactors = __classPrivateFieldGet(this, _Modes_engine, "f").plugins.interactors.get(__classPrivateFieldGet(this, _Modes_container, "f"));
            if (interactors) {
                for (const interactor of interactors) {
                    if (interactor.loadModeOptions) {
                        interactor.loadModeOptions(this, data);
                    }
                }
            }
        }
    }
}
_Modes_container = new WeakMap(), _Modes_engine = new WeakMap();
