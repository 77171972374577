export const generatedAttribute = "generated";
export const touchEndEvent = "touchend";
export const mouseDownEvent = "pointerdown";
export const mouseUpEvent = "pointerup";
export const mouseMoveEvent = "pointermove";
export const touchStartEvent = "touchstart";
export const touchMoveEvent = "touchmove";
export const mouseLeaveEvent = "pointerleave";
export const mouseOutEvent = "pointerout";
export const touchCancelEvent = "touchcancel";
export const resizeEvent = "resize";
export const visibilityChangeEvent = "visibilitychange";
export const noPolygonDataLoaded = "No polygon data loaded.";
export const noPolygonFound = "No polygon found, you need to specify SVG url in config.";
