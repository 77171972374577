var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Container_engine, _Container_eventListeners, _Container_intersectionObserver, _Container_options, _Container_sourceOptions;
import { animate, cancelAnimation } from "../Utils/Utils";
import { Canvas } from "./Canvas";
import { EventListeners } from "./Utils/EventListeners";
import { FrameManager } from "./Utils/FrameManager";
import { Options } from "../Options/Classes/Options";
import { Particles } from "./Particles";
import { Retina } from "./Retina";
import { getRangeValue } from "../Utils/NumberUtils";
import { loadOptions } from "../Utils/OptionsUtils";
function guardCheck(container) {
    return container !== undefined && !container.destroyed;
}
function loadContainerOptions(engine, container, ...sourceOptionsArr) {
    const options = new Options(engine, container);
    loadOptions(options, ...sourceOptionsArr);
    return options;
}
const defaultPathGeneratorKey = "default", defaultPathGenerator = {
    generate: (p) => {
        const v = p.velocity.copy();
        v.angle += (v.length * Math.PI) / 180;
        return v;
    },
    init: () => {
    },
    update: () => {
    },
};
export class Container {
    constructor(engine, id, sourceOptions) {
        this.id = id;
        _Container_engine.set(this, void 0);
        _Container_eventListeners.set(this, void 0);
        _Container_intersectionObserver.set(this, void 0);
        _Container_options.set(this, void 0);
        _Container_sourceOptions.set(this, void 0);
        __classPrivateFieldSet(this, _Container_engine, engine, "f");
        this.fpsLimit = 120;
        this.duration = 0;
        this.lifeTime = 0;
        this.firstStart = true;
        this.started = false;
        this.destroyed = false;
        this.paused = true;
        this.lastFrameTime = 0;
        this.zLayers = 100;
        this.pageHidden = false;
        __classPrivateFieldSet(this, _Container_sourceOptions, sourceOptions, "f");
        this._initialSourceOptions = sourceOptions;
        this.retina = new Retina(this);
        this.canvas = new Canvas(this);
        this.particles = new Particles(__classPrivateFieldGet(this, _Container_engine, "f"), this);
        this.frameManager = new FrameManager(this);
        this.pathGenerators = new Map();
        this.interactivity = {
            mouse: {
                clicking: false,
                inside: false,
            },
        };
        this.plugins = new Map();
        this.drawers = new Map();
        __classPrivateFieldSet(this, _Container_options, loadContainerOptions(__classPrivateFieldGet(this, _Container_engine, "f"), this), "f");
        this.actualOptions = loadContainerOptions(__classPrivateFieldGet(this, _Container_engine, "f"), this);
        __classPrivateFieldSet(this, _Container_eventListeners, new EventListeners(this), "f");
        if (typeof IntersectionObserver !== "undefined" && IntersectionObserver) {
            __classPrivateFieldSet(this, _Container_intersectionObserver, new IntersectionObserver((entries) => this.intersectionManager(entries)), "f");
        }
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("containerBuilt", { container: this });
    }
    get options() {
        return __classPrivateFieldGet(this, _Container_options, "f");
    }
    get sourceOptions() {
        return __classPrivateFieldGet(this, _Container_sourceOptions, "f");
    }
    addClickHandler(callback) {
        if (!guardCheck(this)) {
            return;
        }
        const el = this.interactivity.element;
        if (!el) {
            return;
        }
        const clickOrTouchHandler = (e, pos, radius) => {
            if (!guardCheck(this)) {
                return;
            }
            const pxRatio = this.retina.pixelRatio, posRetina = {
                x: pos.x * pxRatio,
                y: pos.y * pxRatio,
            }, particles = this.particles.quadTree.queryCircle(posRetina, radius * pxRatio);
            callback(e, particles);
        };
        const clickHandler = (e) => {
            if (!guardCheck(this)) {
                return;
            }
            const mouseEvent = e, pos = {
                x: mouseEvent.offsetX || mouseEvent.clientX,
                y: mouseEvent.offsetY || mouseEvent.clientY,
            };
            clickOrTouchHandler(e, pos, 1);
        };
        const touchStartHandler = () => {
            if (!guardCheck(this)) {
                return;
            }
            touched = true;
            touchMoved = false;
        };
        const touchMoveHandler = () => {
            if (!guardCheck(this)) {
                return;
            }
            touchMoved = true;
        };
        const touchEndHandler = (e) => {
            var _a, _b, _c;
            if (!guardCheck(this)) {
                return;
            }
            if (touched && !touchMoved) {
                const touchEvent = e;
                let lastTouch = touchEvent.touches[touchEvent.touches.length - 1];
                if (!lastTouch) {
                    lastTouch = touchEvent.changedTouches[touchEvent.changedTouches.length - 1];
                    if (!lastTouch) {
                        return;
                    }
                }
                const canvasRect = (_a = this.canvas.element) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect(), pos = {
                    x: lastTouch.clientX - ((_b = canvasRect === null || canvasRect === void 0 ? void 0 : canvasRect.left) !== null && _b !== void 0 ? _b : 0),
                    y: lastTouch.clientY - ((_c = canvasRect === null || canvasRect === void 0 ? void 0 : canvasRect.top) !== null && _c !== void 0 ? _c : 0),
                };
                clickOrTouchHandler(e, pos, Math.max(lastTouch.radiusX, lastTouch.radiusY));
            }
            touched = false;
            touchMoved = false;
        };
        const touchCancelHandler = () => {
            if (!guardCheck(this)) {
                return;
            }
            touched = false;
            touchMoved = false;
        };
        let touched = false;
        let touchMoved = false;
        el.addEventListener("click", clickHandler);
        el.addEventListener("touchstart", touchStartHandler);
        el.addEventListener("touchmove", touchMoveHandler);
        el.addEventListener("touchend", touchEndHandler);
        el.addEventListener("touchcancel", touchCancelHandler);
    }
    addPath(key, generator, override = false) {
        if (!guardCheck(this) || (!override && this.pathGenerators.has(key))) {
            return false;
        }
        this.pathGenerators.set(key, generator !== null && generator !== void 0 ? generator : defaultPathGenerator);
        return true;
    }
    destroy() {
        if (!guardCheck(this)) {
            return;
        }
        this.stop();
        this.particles.destroy();
        this.canvas.destroy();
        for (const [, drawer] of this.drawers) {
            if (drawer.destroy) {
                drawer.destroy(this);
            }
        }
        for (const key of this.drawers.keys()) {
            this.drawers.delete(key);
        }
        __classPrivateFieldGet(this, _Container_engine, "f").plugins.destroy(this);
        this.destroyed = true;
        const mainArr = __classPrivateFieldGet(this, _Container_engine, "f").dom(), idx = mainArr.findIndex((t) => t === this);
        if (idx >= 0) {
            mainArr.splice(idx, 1);
        }
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("containerDestroyed", { container: this });
    }
    draw(force) {
        if (!guardCheck(this)) {
            return;
        }
        let refreshTime = force;
        this.drawAnimationFrame = animate()(async (timestamp) => {
            if (refreshTime) {
                this.lastFrameTime = undefined;
                refreshTime = false;
            }
            await this.frameManager.nextFrame(timestamp);
        });
    }
    exportConfiguration() {
        return JSON.stringify(this.actualOptions, undefined, 2);
    }
    exportImage(callback, type, quality) {
        var _a;
        return (_a = this.canvas.element) === null || _a === void 0 ? void 0 : _a.toBlob(callback, type !== null && type !== void 0 ? type : "image/png", quality);
    }
    exportImg(callback) {
        this.exportImage(callback);
    }
    getAnimationStatus() {
        return !this.paused && !this.pageHidden && guardCheck(this);
    }
    handleClickMode(mode) {
        if (!guardCheck(this)) {
            return;
        }
        this.particles.handleClickMode(mode);
        for (const [, plugin] of this.plugins) {
            if (plugin.handleClickMode) {
                plugin.handleClickMode(mode);
            }
        }
    }
    async init() {
        if (!guardCheck(this)) {
            return;
        }
        const shapes = __classPrivateFieldGet(this, _Container_engine, "f").plugins.getSupportedShapes();
        for (const type of shapes) {
            const drawer = __classPrivateFieldGet(this, _Container_engine, "f").plugins.getShapeDrawer(type);
            if (drawer) {
                this.drawers.set(type, drawer);
            }
        }
        __classPrivateFieldSet(this, _Container_options, loadContainerOptions(__classPrivateFieldGet(this, _Container_engine, "f"), this, this._initialSourceOptions, this.sourceOptions), "f");
        this.actualOptions = loadContainerOptions(__classPrivateFieldGet(this, _Container_engine, "f"), this, __classPrivateFieldGet(this, _Container_options, "f"));
        this.retina.init();
        this.canvas.init();
        this.updateActualOptions();
        this.canvas.initBackground();
        this.canvas.resize();
        this.zLayers = this.actualOptions.zLayers;
        this.duration = getRangeValue(this.actualOptions.duration);
        this.lifeTime = 0;
        this.fpsLimit = this.actualOptions.fpsLimit > 0 ? this.actualOptions.fpsLimit : 120;
        const availablePlugins = __classPrivateFieldGet(this, _Container_engine, "f").plugins.getAvailablePlugins(this);
        for (const [id, plugin] of availablePlugins) {
            this.plugins.set(id, plugin);
        }
        for (const [, drawer] of this.drawers) {
            if (drawer.init) {
                await drawer.init(this);
            }
        }
        for (const [, plugin] of this.plugins) {
            if (plugin.init) {
                plugin.init(this.actualOptions);
            }
            else if (plugin.initAsync !== undefined) {
                await plugin.initAsync(this.actualOptions);
            }
        }
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("containerInit", { container: this });
        this.particles.init();
        this.particles.setDensity();
        for (const [, plugin] of this.plugins) {
            if (plugin.particlesSetup !== undefined) {
                plugin.particlesSetup();
            }
        }
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("particlesSetup", { container: this });
    }
    async loadTheme(name) {
        if (!guardCheck(this)) {
            return;
        }
        this.currentTheme = name;
        await this.refresh();
    }
    pause() {
        if (!guardCheck(this)) {
            return;
        }
        if (this.drawAnimationFrame !== undefined) {
            cancelAnimation()(this.drawAnimationFrame);
            delete this.drawAnimationFrame;
        }
        if (this.paused) {
            return;
        }
        for (const [, plugin] of this.plugins) {
            if (plugin.pause) {
                plugin.pause();
            }
        }
        if (!this.pageHidden) {
            this.paused = true;
        }
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("containerPaused", { container: this });
    }
    play(force) {
        if (!guardCheck(this)) {
            return;
        }
        const needsUpdate = this.paused || force;
        if (this.firstStart && !this.actualOptions.autoPlay) {
            this.firstStart = false;
            return;
        }
        if (this.paused) {
            this.paused = false;
        }
        if (needsUpdate) {
            for (const [, plugin] of this.plugins) {
                if (plugin.play) {
                    plugin.play();
                }
            }
        }
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("containerPlay", { container: this });
        this.draw(needsUpdate || false);
    }
    async refresh() {
        if (!guardCheck(this)) {
            return;
        }
        this.stop();
        return this.start();
    }
    async reset() {
        if (!guardCheck(this)) {
            return;
        }
        __classPrivateFieldSet(this, _Container_options, loadContainerOptions(__classPrivateFieldGet(this, _Container_engine, "f"), this), "f");
        return this.refresh();
    }
    setNoise(noiseOrGenerator, init, update) {
        if (!guardCheck(this)) {
            return;
        }
        this.setPath(noiseOrGenerator, init, update);
    }
    setPath(pathOrGenerator, init, update) {
        if (!pathOrGenerator || !guardCheck(this)) {
            return;
        }
        const pathGenerator = Object.assign({}, defaultPathGenerator);
        if (typeof pathOrGenerator === "function") {
            pathGenerator.generate = pathOrGenerator;
            if (init) {
                pathGenerator.init = init;
            }
            if (update) {
                pathGenerator.update = update;
            }
        }
        else {
            const oldGenerator = pathGenerator;
            pathGenerator.generate = pathOrGenerator.generate || oldGenerator.generate;
            pathGenerator.init = pathOrGenerator.init || oldGenerator.init;
            pathGenerator.update = pathOrGenerator.update || oldGenerator.update;
        }
        this.addPath(defaultPathGeneratorKey, pathGenerator, true);
    }
    async start() {
        if (this.started || !guardCheck(this)) {
            return;
        }
        await this.init();
        this.started = true;
        __classPrivateFieldGet(this, _Container_eventListeners, "f").addListeners();
        if (this.interactivity.element instanceof HTMLElement && __classPrivateFieldGet(this, _Container_intersectionObserver, "f")) {
            __classPrivateFieldGet(this, _Container_intersectionObserver, "f").observe(this.interactivity.element);
        }
        for (const [, plugin] of this.plugins) {
            if (plugin.startAsync !== undefined) {
                await plugin.startAsync();
            }
            else if (plugin.start !== undefined) {
                plugin.start();
            }
        }
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("containerStarted", { container: this });
        this.play();
    }
    stop() {
        var _a;
        if (!this.started || !guardCheck(this)) {
            return;
        }
        this.firstStart = true;
        this.started = false;
        __classPrivateFieldGet(this, _Container_eventListeners, "f").removeListeners();
        this.pause();
        this.particles.clear();
        this.canvas.clear();
        if (this.interactivity.element instanceof HTMLElement && __classPrivateFieldGet(this, _Container_intersectionObserver, "f")) {
            __classPrivateFieldGet(this, _Container_intersectionObserver, "f").unobserve(this.interactivity.element);
        }
        for (const [, plugin] of this.plugins) {
            (_a = plugin.stop) === null || _a === void 0 ? void 0 : _a.call(plugin);
        }
        for (const key of this.plugins.keys()) {
            this.plugins.delete(key);
        }
        __classPrivateFieldSet(this, _Container_sourceOptions, __classPrivateFieldGet(this, _Container_options, "f"), "f");
        __classPrivateFieldGet(this, _Container_engine, "f").dispatchEvent("containerStopped", { container: this });
    }
    updateActualOptions() {
        this.actualOptions.responsive = [];
        const newMaxWidth = this.actualOptions.setResponsive(this.canvas.size.width, this.retina.pixelRatio, __classPrivateFieldGet(this, _Container_options, "f"));
        this.actualOptions.setTheme(this.currentTheme);
        if (this.responsiveMaxWidth != newMaxWidth) {
            this.responsiveMaxWidth = newMaxWidth;
            return true;
        }
        return false;
    }
    intersectionManager(entries) {
        if (!guardCheck(this) || !this.actualOptions.pauseOnOutsideViewport) {
            return;
        }
        for (const entry of entries) {
            if (entry.target !== this.interactivity.element) {
                continue;
            }
            (entry.isIntersecting ? this.play : this.pause)();
        }
    }
}
_Container_engine = new WeakMap(), _Container_eventListeners = new WeakMap(), _Container_intersectionObserver = new WeakMap(), _Container_options = new WeakMap(), _Container_sourceOptions = new WeakMap();
